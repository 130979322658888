import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row, Select, Space, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
// import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { DisplayContext } from "../../Context/displayContext";

export default function ViewVenueDepositAddresses() {
  // const disabledDate = (current: any) => {
  //   // Can not select days after today and today
  //   return current > dayjs().tz("America/New_York", true).startOf("d");
  // };
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  const navigate = useNavigate();
  const [, copy] = useCopyToClipboard();
  const [orderCount, setOrderCount] = useState(0);

  const copyMessageToClipboard = (messageToCopy: string) => {
    copy(messageToCopy);
    toast.success(`${messageToCopy} has been copied to clipboard`, {
      position: "top-right",
      autoClose: 7000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [addressesBackup, setAddressBackup] = useState<any[]>([]);
  const [assetTickers, setAssetTickers] = useState<any[]>([]);
  const [addresses, setAddresses] = useState<any[]>([]);
  const [venueList, setVenueList] = useState<any[]>();
  const [assetNetworkList, setAssetNetworkList] = useState<any[]>([]);
  const [filterForm] = Form.useForm();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchDepositAddresses = useCallback(
    (initalFetch = false) => {
      setIsFetching(true);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/deposit-address",
        withCredentials: true,
      })
        .then((res) => {
          const venueNames = [
            ...new Set(res.data.map((row: any) => row.venueName)),
          ];
          const venueNameList = venueNames.map((row) => ({
            label: row,
            key: row,
            value: row,
          }));

          const assetTickers = [
            ...new Set(res.data.map((row: any) => row.assetTicker)),
          ];
          const assetTickerList = assetTickers.map((row) => ({
            label: row,
            key: row,
            value: row,
          }));
          const assetNetworks = [
            ...new Set(res.data.map((row: any) => row.assetNetwork)),
          ];
          const assetNetworkList = assetNetworks.map((row) => ({
            label: row,
            key: row,
            value: row,
          }));

          setOrderCount(res.data.length);
          if (initalFetch) {
            // const defaultDate = dayjs()
            //   .tz("America/New_York", true)
            //   .subtract(1, "d")
            //   .startOf("day");
            const usdtAddresses = res.data.filter(
              (address: any) => address.assetTicker === "USDT",
            );
            const dateAddresses = usdtAddresses;
            console.log(dateAddresses);
            setAddressBackup(res.data);
            setVenueList(venueNameList);
            setAssetTickers(assetTickerList);
            setAssetNetworkList(assetNetworkList);
            setAddresses(dateAddresses);
            setOrderCount(dateAddresses.length);
            filterForm.setFieldValue("assetTickers", ["USDT"]);
            // filterForm.setFieldValue("date", defaultDate);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    [filterForm, navigate],
  );

  const reset = useCallback(() => {
    filterForm.resetFields();
    setAddresses(addressesBackup);
    setOrderCount(addressesBackup.length);
  }, [addressesBackup, filterForm]);

  useEffect(() => {
    fetchDepositAddresses(true);
  }, [fetchDepositAddresses]);

  const [showFilter, setShowFilter] = useState<boolean>(true);

  const applyFilter = useCallback(
    (form: any) => {
      setIsFetching(true);
      let newAddresses = [...addressesBackup];
      if (form.venue && form.venue.length) {
        newAddresses = newAddresses.filter(
          (address: any) => form.venue.indexOf(address.venueName) !== -1,
        );
      }
      if (form.assetTickers && form.assetTickers.length) {
        newAddresses = newAddresses.filter(
          (address: any) =>
            form.assetTickers.indexOf(address.assetTicker) !== -1,
        );
      }
      if (form.assetNetworks && form.assetNetworks.length) {
        newAddresses = newAddresses.filter(
          (address: any) =>
            form.assetNetworks.indexOf(address.assetNetwork) !== -1,
        );
      }
      // if (form.date) {
      //   newAddresses = newAddresses.filter(
      //     (address: any) =>
      //       dayjs(address.createdAt).startOf("d").valueOf() <=
      //         form.date.valueOf() &&
      //       form.date.valueOf() <=
      //         dayjs(address.addressDatetime).endOf("d").valueOf(),
      //   );
      // }

      setAddresses(newAddresses);
      setOrderCount(newAddresses.length);
      setIsFetching(false);
    },
    [addressesBackup],
  );

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const exportTable = useCallback(
    (isCopyToClipboard: boolean) => {
      setIsDownloading(true);
      axios({
        method: "Post",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/venue/deposit-address/export-csv",
        data: {
          addresses,
          isCopyToClipboard,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (isCopyToClipboard) {
            copy(res.data);
            toast.success("Deposit addresses has been copied to clipboard!");
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            let date = new Date();

            link.setAttribute(
              "download",
              `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
                2,
                "0",
              )}${String(date.getDate()).padStart(
                2,
                "0",
              )}_venue_deposit_addresses.csv`,
            );
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(() => {
          navigate("/");
        })
        .finally(() => {
          setIsDownloading(false);
        });
    },
    [addresses, copy, navigate],
  );

  return (
    <Content id="view-venue-deposit-address">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Row>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={applyFilter}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            labelWrap
            disabled={isFetching}
          >
            <Row>
              <Form.Item name="venue" className="dcl-filter-item">
                <Select
                  className="dcl-venue-select"
                  mode="multiple"
                  allowClear
                  placeholder="Venues"
                  options={venueList}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="assetTickers" className="dcl-filter-item">
                <Select
                  className="dcl-venue-select"
                  mode="multiple"
                  allowClear
                  placeholder="Asset Tickers"
                  options={assetTickers}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="assetNetworks" className="dcl-filter-item">
                <Select
                  className="dcl-venue-select"
                  mode="multiple"
                  allowClear
                  placeholder="Asset Networks"
                  options={assetNetworkList}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              {/* <Form.Item name="date" className="dcl-filter-item">
                <DatePicker disabledDate={disabledDate} placeholder={"Date"} />
              </Form.Item> */}
            </Row>
            <Row justify="end">
              <Space>
                {orderCount}
                <b>Results</b>
              </Space>
              <Space>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={reset} disabled={isFetching || isDownloading}>
                  Reset
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => exportTable(true)}
              disabled={isDownloading}
            >
              <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
              Copy to Clipboard
            </Button>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => exportTable(false)}
              loading={isDownloading}
              disabled={isDownloading}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>

      <Table
        loading={isFetching}
        dataSource={addresses}
        sticky
        columns={[
          {
            title: "Venue",
            dataIndex: "venueName",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.venueName.localeCompare(b.venueName),
            render: (_: any, { venueName, venueCcxtKey }: any) => (
              <Space className={`dcl-venue-${venueCcxtKey}`}>{venueName}</Space>
            ),
            width: "150px",
          },
          {
            title: "Asset",
            dataIndex: "assetTicker",
            sorter: (a, b) => a.assetTicker.localeCompare(b.assetTicker),
            render: (_: any, { assetTicker }: any) => (
              <Space
                className={`dcl-asset-network-${assetTicker} dcl-asset-network`}
              >
                {assetTicker}
              </Space>
            ),
            width: "150px",
          },
          {
            title: "Asset Network",
            dataIndex: "assetNetwork",
            sorter: (a, b) => a.assetNetwork.localeCompare(b.assetNetwork),
            render: (_: any, { assetNetwork }: any) => (
              <Space
                className={`dcl-asset-network-${assetNetwork} dcl-asset-network`}
              >
                {assetNetwork}
              </Space>
            ),
            width: "150px",
          },
          {
            title: "Address",
            dataIndex: "addressValue",
            render: (_: any, { addressValue }: any) => (
              <>
                {addressValue}
                <Button
                  onClick={() => copyMessageToClipboard(addressValue)}
                  size={"small"}
                >
                  <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
                </Button>
              </>
            ),
          },
          // {
          //   title: "1st fetched",
          //   dataIndex: "createdAt",
          //   render: (_: any, { createdAt }: any) => (
          //     <>
          //       {new Date(createdAt).toLocaleString("en-us", {
          //         timeZone: displayTimezone,
          //         timeZoneName: "short",
          //       })}
          //     </>
          //   ),
          // },
          {
            title: "Last updated",
            dataIndex: "updatedAt",
            render: (_: any, { updatedAt }: any) => (
              <>
                {new Date(updatedAt).toLocaleString("en-us", {
                  timeZone: displayTimezone,
                  timeZoneName: "short",
                })}
              </>
            ),
          },
        ]}
        scroll={{
          x: 800,
        }}
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: [50, 100],
        }}
      />
    </Content>
  );
}
