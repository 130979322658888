import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  // Card,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import DclQuoteConfirmationModal from "./DclQuoteConfirmModal";

const BPS_FEE = 0.0001;

function CreateNewDclQuote({
  setShowNewOrderBuilder,
  clientList,
  userList,
  venueList,
  transactionWayList,
  dclOrderTypeList,
  assetList,
  fetchdclDisplayedOrders,
  defaultOrderType,
  defaultAssetId,
  defaultVenueId,
  defaultUserId,
  defaultFeeObj,
}: any) {
  const [form] = Form.useForm();
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(true);
  const [symbolPair, setSymbolPair] = useState<string>("");
  const [quoteQty, setQuoteQty] = useState("");
  const [baseQty, setBaseQty] = useState("");
  const [basePrice, setPrice] = useState("");
  const [baseCurrency, setBaseCurrency] = useState("");
  const [quoteCurrency, setQuoteCurrency] = useState("");
  const [baseCurrencyNameDisplay, setBaseCurrencyNameDisplay] = useState("");
  const [quoteCurrencyNameDisplay, setQuoteCurrencyNameDisplay] = useState("");
  const [transactionWayName, setTransactionWayName] = useState("");
  const [messageToCopy, setMessageToCopy] = useState("");
  const [isBase, setIsBase] = useState<boolean>(true);
  const [baseName, setBaseName] = useState<string>("Base");
  const [quoteName, setQuoteName] = useState<string>("Quote");
  const [netPrice, setNetPrice] = useState<string>("");
  const [, copy] = useCopyToClipboard();
  const [clientName, setClientName] = useState<string>("");
  const [deltecAccount, setDeltecAccount] = useState<string>("");
  const [summary, setSummary] = useState<string>("");
  const [includeNetFees, setIncludeNetFees] = useState<boolean>(true);
  const [includeVenueFees, setIncludeVenueFees] = useState<boolean>(true);
  const [netFees, setnetFees] = useState<number>(0);
  const [netFeeQuote, setNetFeeQuote] = useState<number>(0);
  const [isBaseStablecoin, setIsBaseStablecoin] = useState<boolean>(false);
  const [isQuoteFiat, setIsQuoteFiat] = useState<boolean>(true);
  const [includeDelchainFee, setIncludeDelchainFee] = useState<boolean>(true);
  const [venueFees, setVenueFees] = useState<number>(10);
  const [dclFees, setDclFees] = useState<number>(0);
  const [netVenueFeeQuote, setNetVenueFeeQuote] = useState<number>(0);
  const [netDclFeeQuote, setNetDclFeeQuote] = useState<number>(0);
  const [selectedVenue, setSelectedVenue] = useState<number>(defaultVenueId);
  const [isTether, setIsTether] = useState<boolean>(false);
  const [dclFeeCalculationType, setDclFeeCalculationType] =
    useState<string>("bps");
  const feeCalculationTypeOptions = [
    { label: "BPS", value: "bps" },
    { label: "Quote", value: "quote" },
  ];
  // const [selectedBaseAssetId, setSelectedBaseAssetId] = useState<number>();
  const [quoteAmountApplied, setQuoteAmountApplied] = useState<number>(0);

  useEffect(() => {
    if (defaultFeeObj && selectedVenue) {
      const dclFee = isBaseStablecoin
        ? defaultFeeObj[selectedVenue]?.dcl_stable_fees
        : defaultFeeObj[selectedVenue]?.dcl_fees;
      const venueFee = defaultFeeObj[selectedVenue]?.venue_fees;
      setnetFees(venueFee + dclFee);
      setDclFees(dclFee);
      form.setFieldValue("fee", venueFee);
      form.setFieldValue("dclFee", dclFee);
    }
  }, [form, selectedVenue, defaultFeeObj, isBaseStablecoin]);

  const [metMinimumFeeRequirement, setMetMinimumFeeRequirement] =
    useState<boolean>(true);

  const [metMinimumDclFeeRequirement, setMetMinimumDclFeeRequirement] =
    useState<boolean>(true);

  const submitData = useCallback(
    (type: string) => {
      form
        .validateFields()
        .then(() => {
          const fm = form.getFieldsValue();
          setOnFinishLoaded(false);
          axios({
            method: "POST",
            url:
              process.env.REACT_APP_AWS_BACKEND_URL + "/venue/dcl-order-data",
            data: {
              timestamp: dayjs(fm.timestamp)
                .tz("America/New_York", true)
                .format(),
              clientId: fm.clientId,
              dclUserId: fm.dclUserId,
              dclOrderTypeId: fm.dclOrderTypeId,
              venueId: fm.venueId,
              firstAssetId: fm.firstAssetId,
              secondAssetId: fm.secondAssetId,
              transactionWayId: fm.transactionWayId,
              quantity: fm.qty,
              price: fm.price,
              note: fm.note,
              fee: fm.fee ? fm.fee : 0,
              dclFee: dclFees,
              message: messageToCopy,
              summary: summary,
              isBase: isBase,
              isCanceled: type === "canceled" ? true : false,
              isExecuted: type === "executed" ? true : false,
            },
            withCredentials: true,
          })
            .then(() => {
              toast.success("Order created successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowNewOrderBuilder(false);
              fetchdclDisplayedOrders({ initialFetch: false });
            })
            .catch(() => {
              toast.error("Failed to create order", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .finally(() => {
              setOnFinishLoaded(true);
            });
        })
        .catch(() => {
          // setShowConfirmModal(false);
          toast.error("Please check the fields");
        })
        .finally(() => {
          setShowConfirmModal(false);
          setSaveType("");
        });
      return;
    },
    [
      dclFees,
      fetchdclDisplayedOrders,
      form,
      isBase,
      messageToCopy,
      setShowNewOrderBuilder,
      summary,
    ],
  );

  const onAssetSelect = useCallback(() => {
    if (
      form.getFieldValue("firstAssetId") &&
      form.getFieldValue("secondAssetId")
    ) {
      const baseAsset = assetList.find(
        (e: any) => e.value === form.getFieldValue("firstAssetId"),
      );
      const quoteAsset = assetList.find(
        (e: any) => e.value === form.getFieldValue("secondAssetId"),
      );

      setIsBaseStablecoin(baseAsset.isStablecoin);
      setIsQuoteFiat(quoteAsset.assetType?.toLocaleLowerCase() === "fiat");

      const baseCurrencyLabel = baseAsset.label
        .split("(")[1]
        .split(")")[0]
        .trim();

      const quoteCurrencyLabel = quoteAsset.label
        .split("(")[1]
        .split(")")[0]
        .trim();
      setBaseCurrency(baseCurrencyLabel);
      setQuoteCurrency(quoteCurrencyLabel);
      if (isBase) {
        setBaseCurrencyNameDisplay(baseCurrencyLabel);
        setQuoteCurrencyNameDisplay(quoteCurrencyLabel);
      } else {
        setBaseCurrencyNameDisplay(quoteCurrencyLabel);
        setQuoteCurrencyNameDisplay(baseCurrencyLabel);
      }
      setSymbolPair(`${baseCurrencyLabel}/${quoteCurrencyLabel}`);
    }
  }, [form, assetList, isBase]);

  const setBaseAsset = useCallback(
    (assetTicker: string) => {
      const baseAsset = assetList?.find(
        (row: any) => row.ticker === assetTicker,
      );
      form.setFieldValue("firstAssetId", baseAsset.value);
      onAssetSelect();
    },
    [assetList, form, onAssetSelect],
  );

  const onQtyChange = useCallback(() => {
    let qty = form.getFieldValue("qty") ? Number(form.getFieldValue("qty")) : 0;
    const price = form.getFieldValue("price")
      ? Number(form.getFieldValue("price"))
      : 0;

    const fee = form.getFieldValue("fee")
      ? Number(form.getFieldValue("fee"))
      : 0;
    const dclFee = form.getFieldValue("dclFee")
      ? Number(form.getFieldValue("dclFee"))
      : 0;
    const includeVenueFees = form.getFieldValue("includeFees");
    const includeDclFees = form.getFieldValue("includeDclFees");
    if (isNaN(qty) || isNaN(price) || isNaN(fee)) {
      if (quoteQty) {
        setQuoteQty("0");
      }
      return;
    }
    setBaseQty(
      qty.toLocaleString("en-us", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 6,
      }),
    );
    setPrice(
      price.toLocaleString("en-us", {
        minimumFractionDigits: 4,
        maximumFractionDigits: 6,
      }),
    );
    let nPrice = 1;
    nPrice += includeVenueFees
      ? Number(fee) * BPS_FEE * (transactionWayName === "sell" ? -1 : 1)
      : 0;
    nPrice += includeDclFees
      ? Number(dclFee) * BPS_FEE * (transactionWayName === "sell" ? -1 : 1)
      : 0;
    nPrice *= Number(price);
    nPrice = Number(nPrice.toFixed(isBaseStablecoin ? 4 : 6));
    setNetPrice(String(nPrice));
    if (isBase) {
      setQuoteQty(
        (Number(qty) * Number(nPrice)).toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: isBaseStablecoin ? 4 : 6,
        }),
      );
    } else {
      const netFeeQuote = (fee + dclFee) * qty * BPS_FEE;
      if (netFeeQuote < defaultFeeObj[selectedVenue]?.min_dcl_net_fee) {
        qty =
          qty +
          defaultFeeObj[selectedVenue]?.min_dcl_net_fee *
            (transactionWayName === "buy" ? -1 : 1);
        nPrice = Number(
          (
            (1 +
              (includeVenueFees
                ? Number(form.getFieldValue("fee")) *
                  BPS_FEE *
                  (transactionWayName === "sell" ? -1 : 1)
                : 0)) *
            price
          ).toFixed(isBaseStablecoin ? 4 : 6),
        );
      }
      setQuoteQty(
        (Number(qty) / Number(nPrice)).toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: isBaseStablecoin ? 4 : 6,
        }),
      );
    }
  }, [
    form,
    transactionWayName,
    isBaseStablecoin,
    isBase,
    quoteQty,
    defaultFeeObj,
    selectedVenue,
  ]);

  const onTransactionWaySelect = useCallback(() => {
    if (form.getFieldValue("transactionWayId")) {
      const transactionWayName = transactionWayList
        .find((e: any) => e.value === form.getFieldValue("transactionWayId"))
        .label.toLowerCase();
      setTransactionWayName(transactionWayName);
      const price = form.getFieldValue("price")
        ? Number(form.getFieldValue("price"))
        : 0;
      const includeVenueFees = form.getFieldValue("includeFees");
      const includeDclFees = form.getFieldValue("includeDclFees");
      let nPrice = 1;
      const dclBps =
        (includeVenueFees ? Number(form.getFieldValue("fee")) : 0) +
        includeDclFees
          ? Number(form.getFieldValue("dclFee"))
          : 0;
      nPrice += includeVenueFees
        ? Number(form.getFieldValue("fee")) *
          BPS_FEE *
          (transactionWayName === "sell" ? -1 : 1)
        : 0;
      nPrice += includeDclFees
        ? Number(form.getFieldValue("dclFee")) *
          BPS_FEE *
          (transactionWayName === "sell" ? -1 : 1)
        : 0;
      nPrice *= price;
      let qty = form.getFieldValue("qty")
        ? Number(form.getFieldValue("qty"))
        : 0;
      nPrice = Number(nPrice.toFixed(isBaseStablecoin ? 4 : 6));
      setNetPrice(String(nPrice));
      if (isBase) {
        setQuoteQty(
          (Number(qty) * Number(nPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          }),
        );
      } else {
        const netFeeQuote = dclBps * qty * BPS_FEE;
        if (netFeeQuote < defaultFeeObj[selectedVenue]?.min_dcl_net_fee) {
          qty =
            qty +
            defaultFeeObj[selectedVenue]?.min_dcl_net_fee *
              (transactionWayName === "buy" ? -1 : 1);
          nPrice = Number(
            (
              (1 +
                (includeVenueFees
                  ? Number(form.getFieldValue("fee")) *
                    BPS_FEE *
                    (transactionWayName === "sell" ? -1 : 1)
                  : 0)) *
              price
            ).toFixed(isBaseStablecoin ? 4 : 6),
          );
        }
        setQuoteQty(
          (Number(qty) / Number(nPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          }),
        );
      }
    }
  }, [
    form,
    transactionWayList,
    isBaseStablecoin,
    isBase,
    defaultFeeObj,
    selectedVenue,
  ]);

  const onFeeChange = useCallback(() => {
    const price = form.getFieldValue("price")
      ? Number(form.getFieldValue("price"))
      : 0;
    const includeVenueFees = form.getFieldValue("includeFees");
    const includeDclFees = form.getFieldValue("includeDclFees");
    let qty = form.getFieldValue("qty") ? Number(form.getFieldValue("qty")) : 0;
    const venueBps = Number(form.getFieldValue("fee")) || 0;
    const dclBps = Number(form.getFieldValue("dclFee")) || 0;
    if (includeDclFees && includeVenueFees) {
      setnetFees(venueBps + dclBps);
      setVenueFees(venueBps);
      setDclFees(dclBps);
    } else if (includeDclFees) {
      setnetFees(dclBps);
      setVenueFees(0);
      setDclFees(dclBps);
    } else if (includeVenueFees) {
      setnetFees(venueBps);
      setVenueFees(venueBps);
      setDclFees(0);
    } else {
      setnetFees(0);
      setVenueFees(0);
      setDclFees(0);
    }
    let nPrice = 1;
    nPrice += includeVenueFees
      ? Number(form.getFieldValue("fee")) *
        BPS_FEE *
        (transactionWayName === "sell" ? -1 : 1)
      : 0;
    nPrice += includeDclFees
      ? Number(form.getFieldValue("dclFee")) *
        BPS_FEE *
        (transactionWayName === "sell" ? -1 : 1)
      : 0;
    nPrice *= price;
    nPrice = Number(nPrice.toFixed(isBaseStablecoin ? 4 : 6));
    setNetPrice(String(nPrice));

    if (isBase) {
      setQuoteQty(
        (Number(qty) * Number(nPrice)).toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: isBaseStablecoin ? 4 : 6,
        }),
      );
    } else {
      const netFeeQuote = dclBps * qty * BPS_FEE;
      if (netFeeQuote < defaultFeeObj[selectedVenue]?.min_dcl_net_fee) {
        qty =
          qty +
          defaultFeeObj[selectedVenue]?.min_dcl_net_fee *
            (transactionWayName === "buy" ? -1 : 1);
        nPrice = Number(
          (
            (1 +
              (includeVenueFees
                ? Number(form.getFieldValue("fee")) *
                  BPS_FEE *
                  (transactionWayName === "sell" ? -1 : 1)
                : 0)) *
            price
          ).toFixed(isBaseStablecoin ? 4 : 6),
        );
      }
      setQuoteQty(
        (Number(qty) / Number(nPrice)).toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: isBaseStablecoin ? 4 : 6,
        }),
      );
    }
  }, [
    form,
    isBase,
    isBaseStablecoin,
    transactionWayName,
    defaultFeeObj,
    selectedVenue,
  ]);

  const onDclNetFeeQuoteChange = useCallback(
    (e: number | null) => {
      if (!e) return;

      let qty = form.getFieldValue("qty");
      const price = form.getFieldValue("price");
      const baseVolume = qty * price * BPS_FEE;
      const dclFeeBps = Number((e / baseVolume).toFixed(6));
      let nPrice = 1;
      nPrice += includeVenueFees
        ? Number(venueFees) * BPS_FEE * (transactionWayName === "sell" ? -1 : 1)
        : 0;
      nPrice += dclFeeBps * BPS_FEE;
      nPrice *= Number(price);
      nPrice = Number(nPrice.toFixed(isBaseStablecoin ? 4 : 6));
      setNetPrice(String(nPrice));
      setDclFees(dclFeeBps);
      setnetFees(dclFeeBps + venueFees);
      setNetFeeQuote(netVenueFeeQuote + e);
      setNetDclFeeQuote(e);
      if (isBase) {
        setQuoteQty(
          (Number(qty) * Number(nPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
          }),
        );
      } else {
        const netFeeQuote = e;
        if (netFeeQuote < defaultFeeObj[selectedVenue]?.min_dcl_net_fee) {
          qty =
            qty +
            defaultFeeObj[selectedVenue]?.min_dcl_net_fee *
              (transactionWayName === "buy" ? -1 : 1);
          nPrice = Number(
            (
              (1 +
                (includeVenueFees
                  ? Number(form.getFieldValue("fee")) *
                    BPS_FEE *
                    (transactionWayName === "sell" ? -1 : 1)
                  : 0)) *
              price
            ).toFixed(isBaseStablecoin ? 4 : 6),
          );
        }
        setQuoteQty(
          (Number(qty) / Number(nPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          }),
        );
      }
    },
    [
      form,
      includeVenueFees,
      venueFees,
      transactionWayName,
      isBaseStablecoin,
      netVenueFeeQuote,
      isBase,
      defaultFeeObj,
      selectedVenue,
    ],
  );

  const onIncludeFeeCheck = useCallback(() => {
    let qty = form.getFieldValue("qty") ? form.getFieldValue("qty") : 0;
    const price = form.getFieldValue("price") ? form.getFieldValue("price") : 0;
    const fee = form.getFieldValue("fee") ? form.getFieldValue("fee") : 0;
    const dclFee = form.getFieldValue("dclFee")
      ? Number(form.getFieldValue("dclFee"))
      : 0;
    const includeVenueFee = form.getFieldValue("includeFees");
    const includeDclFee = form.getFieldValue("includeDclFees");
    setIncludeDelchainFee(includeDclFee);
    setIncludeVenueFees(includeVenueFee);
    setIncludeNetFees(includeDclFee || includeVenueFee);
    if (includeDclFee && includeVenueFee) {
      setnetFees(fee + dclFee);
      setDclFees(dclFee);
    } else if (includeDclFee) {
      setnetFees(dclFee);
      setDclFees(dclFee);
    } else if (includeVenueFee) {
      setnetFees(fee);
      setDclFees(0);
    } else {
      setnetFees(0);
      setDclFees(0);
    }
    let nPrice = 1;
    nPrice += includeVenueFee
      ? fee * BPS_FEE * (transactionWayName === "sell" ? -1 : 1)
      : 0;
    nPrice += includeDclFee
      ? dclFee * BPS_FEE * (transactionWayName === "sell" ? -1 : 1)
      : 0;
    nPrice *= Number(price);

    nPrice = Number(nPrice.toFixed(isBaseStablecoin ? 4 : 6));
    setNetPrice(String(nPrice));
    if (isBase) {
      setQuoteQty(
        (Number(qty) * Number(nPrice)).toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: isBaseStablecoin ? 4 : 6,
        }),
      );
    } else {
      const netFeeQuote = dclFee * qty * BPS_FEE;
      if (netFeeQuote < defaultFeeObj[selectedVenue]?.min_dcl_net_fee) {
        qty =
          qty +
          defaultFeeObj[selectedVenue]?.min_dcl_net_fee *
            (transactionWayName === "buy" ? -1 : 1);
        nPrice = Number(
          (
            (1 +
              (includeVenueFees
                ? Number(form.getFieldValue("fee")) *
                  BPS_FEE *
                  (transactionWayName === "sell" ? -1 : 1)
                : 0)) *
            price
          ).toFixed(isBaseStablecoin ? 4 : 6),
        );
      }
      setQuoteQty(
        (Number(qty) / Number(nPrice)).toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: isBaseStablecoin ? 4 : 6,
        }),
      );
    }
  }, [
    form,
    isBase,
    isBaseStablecoin,
    transactionWayName,
    includeVenueFees,
    selectedVenue,
    defaultFeeObj,
  ]);

  const copyMessageToClipboard = () => {
    copy(messageToCopy);
    toast.success("Propsition message copied to clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const copySummaryToClipboard = () => {
    copy(summary);
    toast.success("Trade summary copied to clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onRadioChange = useCallback(() => {
    const price = form.getFieldValue("price")
      ? Number(form.getFieldValue("price"))
      : 0;
    const checked = form.getFieldValue("includeFees");
    let qty = form.getFieldValue("qty") ? Number(form.getFieldValue("qty")) : 0;
    let nPrice = 1;
    if (form.getFieldValue("isBase")) {
      setIsBase(true);
      setBaseName("Base");
      setQuoteName("Quote");
      setBaseCurrencyNameDisplay(baseCurrency);
      setQuoteCurrencyNameDisplay(quoteCurrency);
      if (checked) {
        setQuoteQty(
          (Number(qty) * Number(netPrice.split(",").join(""))).toLocaleString(
            "en-us",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6,
            },
          ),
        );
      } else {
        setQuoteQty(
          (Number(qty) * Number(price)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
          }),
        );
      }
    } else {
      setIsBase(false);
      setBaseName("Quote");
      setQuoteName("Base");
      setBaseCurrencyNameDisplay(quoteCurrency);
      setQuoteCurrencyNameDisplay(baseCurrency);
      const dclBps = Number(form.getFieldValue("dclFee")) || 0;
      const netFeeQuote = qty * dclBps * BPS_FEE;
      if (netFeeQuote < defaultFeeObj[selectedVenue]?.min_dcl_net_fee) {
        qty =
          qty +
          defaultFeeObj[selectedVenue]?.min_dcl_net_fee *
            (transactionWayName === "buy" ? -1 : 1);
        nPrice = Number(
          (
            (1 +
              (includeVenueFees
                ? Number(form.getFieldValue("fee")) *
                  BPS_FEE *
                  (transactionWayName === "sell" ? -1 : 1)
                : 0)) *
            price
          ).toFixed(isBaseStablecoin ? 4 : 6),
        );
      }
      if (checked) {
        setQuoteQty(
          (Number(qty) / Number(nPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          }),
        );
      } else {
        setQuoteQty(
          (Number(qty) / Number(price)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          }),
        );
      }
    }
  }, [
    form,
    baseCurrency,
    quoteCurrency,
    netPrice,
    defaultFeeObj,
    selectedVenue,
    transactionWayName,
    includeVenueFees,
    isBaseStablecoin,
  ]);
  const onDclFeeTypeChange = useCallback(
    (e: any) => {
      if (e.target.value === "bps") {
        setDclFees(dclFees);
      }
      setDclFeeCalculationType(e.target.value);
    },
    [dclFees],
  );
  useEffect(() => {
    const quantity = form.getFieldValue("qty") ? form.getFieldValue("qty") : 0;
    const p = form.getFieldValue("price") ? form.getFieldValue("price") : 0;
    let res, dclFeeRes, venueFeeRes;
    if (isBase) {
      res = netFees * BPS_FEE * quantity * p;
      dclFeeRes = dclFees * BPS_FEE * quantity * p;
      venueFeeRes = venueFees * BPS_FEE * quantity * p;
    } else {
      res = netFees * BPS_FEE * Number(quoteQty.replaceAll(",", "")) * p;
      dclFeeRes = dclFees * BPS_FEE * Number(quoteQty.replaceAll(",", "")) * p;
      venueFeeRes =
        venueFees * BPS_FEE * Number(quoteQty.replaceAll(",", "")) * p;
    }
    setNetFeeQuote(res);
    setNetDclFeeQuote(dclFeeRes);
    setNetVenueFeeQuote(venueFeeRes);
  }, [
    form,
    venueFees,
    dclFees,
    netFees,
    baseQty,
    quoteQty,
    quoteCurrency,
    isBase,
  ]);

  useEffect(() => {
    if (netFeeQuote < defaultFeeObj[selectedVenue]?.min_net_fee) {
      setMetMinimumFeeRequirement(false);
    } else {
      setMetMinimumFeeRequirement(true);
    }
  }, [defaultFeeObj, netFeeQuote, selectedVenue]);

  useEffect(() => {
    if (netDclFeeQuote < defaultFeeObj[selectedVenue]?.min_dcl_net_fee) {
      setMetMinimumDclFeeRequirement(false);
    } else {
      setMetMinimumDclFeeRequirement(true);
    }
  }, [defaultFeeObj, netDclFeeQuote, selectedVenue]);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [saveType, setSaveType] = useState<string>("");

  const [minimumAmountComponent, setMinimumAmountComponent] = useState<any>(
    <></>,
  );
  const renderMinimumAmount = useCallback(() => {
    if (
      !defaultFeeObj ||
      !assetList ||
      !selectedVenue ||
      !basePrice ||
      !baseQty
    )
      return;
    if (
      (metMinimumDclFeeRequirement && metMinimumFeeRequirement) ||
      !transactionWayName
    ) {
      setMinimumAmountComponent(<></>);
      return;
    }
    const includeVenueFees = form.getFieldValue("includeFees");
    const includeDclFees = form.getFieldValue("includeDclFees");
    const isBase = form.getFieldValue("isBase");

    const venueBps = includeVenueFees ? form.getFieldValue("fee") : 0;
    const dclBps = includeDclFees ? form.getFieldValue("dclFee") : 0;
    let qty = Number(baseQty?.replaceAll(",", ""));
    const price = Number(basePrice?.replaceAll(",", ""));
    const minDclFee = defaultFeeObj[selectedVenue]?.min_dcl_net_fee || 0;
    const minNetFee = defaultFeeObj[selectedVenue]?.min_net_fee || 0;

    const dclFee = includeDclFees
      ? Math.max(
          isBase
            ? qty * price * dclBps * BPS_FEE
            : Number(quoteQty.replaceAll(",", "")) * price * dclBps * BPS_FEE,
          minDclFee,
        )
      : 0;
    const venueFee = includeVenueFees
      ? isBase
        ? qty * price * venueBps * BPS_FEE
        : venueBps * BPS_FEE * Number(quoteQty.replaceAll(",", "")) * price
      : 0;
    let netFee = 0;
    if (includeDclFees && includeVenueFees) {
      netFee = Math.max(dclFee + venueFee, minNetFee);
    } else if (includeDclFees) {
      netFee = Math.max(dclFee, minNetFee);
    } else if (includeVenueFees) {
      netFee = Math.max(venueFee, minNetFee);
    }
    if (netFee < 0) netFee = 0;

    const quoteAmount = isBase
      ? qty * price +
        netFee * (transactionWayName?.toLocaleLowerCase() === "buy" ? 1 : -1)
      : Number(quoteQty.replaceAll(",", ""));
    setQuoteAmountApplied(quoteAmount);
    const secondAsset = form.getFieldValue("secondAssetId");
    const minFeeApplied = isQuoteFiat ? Number(netFee.toFixed(2)) : netFee;
    setMinimumAmountComponent(
      <React.Fragment>
        <Divider />
        {
          /**
           * Hardcoded Tether alert
           */
          isTether && transactionWayName?.toLocaleLowerCase() === "sell" ? (
            <Alert
            style={{padding: '0px 12px'}}
              type="warning"
              message={
                <p style={{ color: "black" }}>
                  <b>WARNING:</b> For redemptions, minimum fees for Tether are
                  $1,000.00
                </p>
              }
            />
          ) : undefined
        }
        <Form.Item label={<b>Min Fee Applied</b>}>
          <Space className="calculated">
            {minFeeApplied.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: isQuoteFiat ? 2 : 6,
            })}
            {assetList.find((row: any) => row.value === secondAsset)?.ticker}
          </Space>
        </Form.Item>

        <Form.Item label={<b>Min Fee Implied Price</b>}>
          <Row className="calculated">
            {isBase
              ? (quoteAmount / form.getFieldValue("qty")).toLocaleString(
                  "en-us",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: isBaseStablecoin ? 4 : 6,
                  },
                )
              : (qty / quoteAmount).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isBaseStablecoin ? 4 : 6,
                })}
          </Row>
        </Form.Item>
        <Form.Item label={<b>Quote amount Applied</b>}>
          <Space className="calculated">
            {isBase
              ? (isQuoteFiat
                  ? Number(quoteAmount.toFixed(2))
                  : quoteAmount
                ).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isQuoteFiat
                    ? 2
                    : isBaseStablecoin
                    ? 4
                    : 6,
                })
              : qty.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isQuoteFiat
                    ? 2
                    : isBaseStablecoin
                    ? 4
                    : 6,
                })}
            {assetList.find((row: any) => row.value === secondAsset)?.ticker}
          </Space>
        </Form.Item>
      </React.Fragment>,
    );
  }, [
    isTether,
    isBaseStablecoin,
    assetList,
    basePrice,
    baseQty,
    defaultFeeObj,
    form,
    isQuoteFiat,
    metMinimumDclFeeRequirement,
    metMinimumFeeRequirement,
    quoteQty,
    selectedVenue,
    transactionWayName,
  ]);

  useEffect(() => {
    renderMinimumAmount();
  }, [renderMinimumAmount]);
  const updateMsg = useCallback(() => {
    if (transactionWayName && baseCurrency && quoteCurrency) {
      if (isBase) {
        if (!metMinimumDclFeeRequirement && includeDelchainFee) {
          setMessageToCopy(
            `Indicatively, ${
              transactionWayName.toLowerCase() === "buy" ? "Buying" : "Selling"
            } ${baseQty} ${baseCurrency} will ${
              transactionWayName.toLowerCase() === "buy" ? "require" : "give"
            } you ${quoteAmountApplied?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: isQuoteFiat ? 2 : 6,
            })} ${quoteCurrency}`,
          );
        } else {
          setMessageToCopy(
            `Indicative quote to ${
              transactionWayName.toLowerCase() === "buy" ? "buy" : "sell"
            } ${baseQty} ${baseCurrency} is ${
              isBaseStablecoin
                ? Number(
                    Number(netPrice.replaceAll(",", "")).toFixed(4),
                  ).toLocaleString("en-us", {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })
                : Number(netPrice).toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 6,
                  })
            } which would ${
              transactionWayName.toLowerCase() === "buy"
                ? "require"
                : "give you"
            } ${
              isQuoteFiat
                ? Number(
                    Number(quoteQty.replaceAll(",", "")).toFixed(2),
                  ).toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : quoteQty
            } ${quoteCurrency}`,
          );
        }
      } else {
        if (!metMinimumDclFeeRequirement && includeDelchainFee) {
          setMessageToCopy(
            `Indicatively, to ${
              transactionWayName.toLowerCase() === "buy" ? "buy" : "sell"
            } ${baseCurrency} ${
              transactionWayName.toLowerCase() === "buy" ? "with" : "to get you"
            } ${baseQty} ${quoteCurrency} will ${
              transactionWayName.toLowerCase() === "buy"
                ? "give you"
                : "require"
            } ${quoteQty} ${baseCurrency}`,
          );
        } else {
          // Met fee
          setMessageToCopy(
            `Indicative quote to ${
              transactionWayName.toLowerCase() === "buy" ? "buy" : "sell"
            } ${baseCurrency} ${
              transactionWayName.toLowerCase() === "buy" ? "with" : "to get you"
            } ${baseQty} ${quoteCurrency} is ${
              isBaseStablecoin
                ? Number(
                    Number(netPrice.replaceAll(",", "")).toFixed(4),
                  ).toLocaleString("en-us", {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })
                : Number(netPrice).toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 6,
                  })
            } which would ${
              transactionWayName.toLowerCase() === "buy"
                ? "give you"
                : "require"
            } ${quoteQty} ${baseCurrency}`,
          );
        }
      }
    } else {
      setMessageToCopy("");
    }
  }, [
    baseCurrency,
    baseQty,
    includeDelchainFee,
    isBase,
    isBaseStablecoin,
    isQuoteFiat,
    metMinimumDclFeeRequirement,
    netPrice,
    quoteAmountApplied,
    quoteCurrency,
    quoteQty,
    transactionWayName,
  ]);

  useEffect(() => {
    updateMsg();
  }, [updateMsg]);

  useEffect(() => {
    if (
      baseCurrency &&
      transactionWayName &&
      clientName &&
      quoteCurrency &&
      netPrice &&
      quoteQty
    ) {
      if (isBase) {
        if (includeDelchainFee && !metMinimumDclFeeRequirement) {
          setSummary(
            `#TRADESUMMARY ${deltecAccount} ${clientName} ${
              transactionWayName.toLowerCase() === "buy" ? "Bought" : "Sold"
            } ${baseQty} ${baseCurrency}. ${
              transactionWayName.toLowerCase() === "buy" ? "Costs" : "Proceeds"
            }: ${quoteAmountApplied?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: isQuoteFiat ? 2 : isBaseStablecoin ? 4 : 6,
            })} ${quoteCurrency}`,
          );
        } else {
          setSummary(
            `#TRADESUMMARY ${deltecAccount} ${clientName} ${
              transactionWayName.toLowerCase() === "buy" ? "Bought" : "Sold"
            } ${baseQty} ${baseCurrency} for a net price ${
              isBaseStablecoin
                ? Number(
                    Number(netPrice.replaceAll(",", "")).toFixed(4),
                  ).toLocaleString("en-us", {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })
                : Number(netPrice).toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 6,
                  })
            }. ${
              transactionWayName.toLowerCase() === "buy" ? "Costs" : "Proceeds"
            }: ${
              isQuoteFiat
                ? Number(
                    Number(quoteQty.replaceAll(",", "")).toFixed(2),
                  ).toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : quoteQty
            } ${quoteCurrency}`,
          );
        }
      } else {
        if (includeDelchainFee && !metMinimumDclFeeRequirement) {
          setSummary(
            `#TRADESUMMARY ${deltecAccount} ${clientName} ${
              transactionWayName.toLowerCase() === "buy" ? "Bought" : "Sold"
            } ${quoteQty} ${baseCurrency}. ${
              transactionWayName.toLowerCase() === "buy" ? "Costs" : "Proceeds"
            }: ${baseQty} ${quoteCurrency}`,
          );
        } else {
          setSummary(
            `#TRADESUMMARY ${deltecAccount} ${clientName} ${
              transactionWayName.toLowerCase() === "buy" ? "Bought" : "Sold"
            } ${quoteQty} ${baseCurrency} for a net price ${netPrice}. ${
              transactionWayName.toLowerCase() === "buy" ? "Costs" : "Proceeds"
            }: ${baseQty} ${quoteCurrency}`,
          );
        }
      }
    } else {
      setSummary("");
    }
  }, [
    baseCurrency,
    baseQty,
    clientName,
    deltecAccount,
    netPrice,
    isBase,
    isBaseStablecoin,
    isQuoteFiat,
    quoteQty,
    quoteCurrency,
    transactionWayName,
    includeDelchainFee,
    metMinimumDclFeeRequirement,
    netDclFeeQuote,
    defaultFeeObj,
    selectedVenue,
    quoteAmountApplied,
  ]);

  return onFinishLoaded ? (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            itemMarginBottom: 10,
          },
        },
      }}
    >
      <React.Fragment>
        <Divider />
        <Row justify="center" style={{ marginTop: "20px" }}>
          <h2>Quote Builder</h2>
        </Row>
        <Content id="order-builder-form">
          <Form
            form={form}
            autoComplete="off"
            onFinish={submitData}
            onFinishFailed={() => {
              toast.error("Please check the form", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }}
            style={{ margin: "2em" }}
          >
            <Row justify={"space-between"}>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Date</b>}
                  name="timestamp"
                  rules={[
                    {
                      required: true,
                      message: "Please select date!",
                    },
                  ]}
                  initialValue={dayjs()}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>dcl User</b>}
                  name="dclUserId"
                  rules={[
                    {
                      required: true,
                      message: "Please select user!",
                    },
                  ]}
                  initialValue={defaultUserId}
                >
                  <Select
                    showSearch
                    style={{ maxWidth: "180px" }}
                    popupMatchSelectWidth={false}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    options={userList}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Order Type</b>}
                  name="dclOrderTypeId"
                  rules={[
                    {
                      required: true,
                      message: "Please select order type!",
                    },
                  ]}
                  initialValue={defaultOrderType}
                >
                  <Select
                    showSearch
                    style={{ maxWidth: "180px" }}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    options={dclOrderTypeList}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Client</b>}
                  name="clientId"
                  rules={[
                    {
                      required: true,
                      message: "Please select client!",
                    },
                  ]}
                >
                  <Select
                    style={{ maxWidth: "180px" }}
                    showSearch
                    popupMatchSelectWidth={false}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    options={clientList}
                    onChange={(value, option: any) => {
                      const dclAcc = option.deltecAccount;
                      const clientName = option.clientName;
                      setDeltecAccount(dclAcc);
                      setClientName(clientName);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Venue</b>}
                  name="venueId"
                  initialValue={defaultVenueId}
                >
                  <Select
                    showSearch
                    allowClear
                    onChange={(e, option: any) => {
                      // Hardcoded
                      setIsTether(
                        option?.label?.toLocaleLowerCase() === "tether",
                      );
                      setSelectedVenue(e);
                    }}
                    value={selectedVenue}
                    style={{ maxWidth: "180px" }}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    options={venueList}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row align="top" justify={"space-between"}>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Base Currency</b>}
                  name="firstAssetId"
                  rules={[
                    {
                      required: true,
                      message: "Please select currency!",
                    },
                  ]}
                  style={{ marginBottom: "0px" }}
                >
                  <Select
                    showSearch
                    style={{ maxWidth: "180px" }}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    popupMatchSelectWidth={false}
                    onChange={onAssetSelect}
                    options={assetList}
                  />
                </Form.Item>
                <Row justify={"center"}>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
                    <Row justify={"start"}>
                      <Button
                        className="dsp-button-usdt"
                        onClick={() => {
                          setBaseAsset("USDT");
                        }}
                      >
                        USDT
                      </Button>
                      <Button
                        className="dsp-button-usdc"
                        onClick={() => {
                          setBaseAsset("USDC");
                        }}
                      >
                        USDC
                      </Button>
                      <Button
                        className="dsp-button-btc"
                        onClick={() => {
                          setBaseAsset("BTC");
                        }}
                      >
                        BTC
                      </Button>

                      <Button
                        className="dsp-button-eth"
                        onClick={() => {
                          setBaseAsset("ETH");
                        }}
                      >
                        ETH
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Quote Currency</b>}
                  name="secondAssetId"
                  rules={[
                    {
                      required: true,
                      message: "Please select venue!",
                    },
                  ]}
                  initialValue={defaultAssetId}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    popupMatchSelectWidth={false}
                    onChange={onAssetSelect}
                    options={assetList}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item label={<b>Symbol</b>}>{symbolPair}</Form.Item>
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: "20px" }}>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Transaction Way</b>}
                  name="transactionWayId"
                  rules={[
                    {
                      required: true,
                      message: "Please select venue!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={onTransactionWaySelect}
                    buttonStyle="solid"
                  >
                    {transactionWayList.map((transanctionWay: any) => (
                      <Radio.Button
                        key={transanctionWay.value}
                        value={transanctionWay.value}
                        className={`dcl-${transanctionWay.label?.toLowerCase()}`}
                      >
                        {transanctionWay.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={<b>Base/Quote</b>}
                  name="isBase"
                  initialValue={isBase}
                  rules={[
                    {
                      required: true,
                      message: "Please select base or quote!",
                    },
                  ]}
                >
                  <Radio.Group onChange={onRadioChange}>
                    <Radio value={true}>Base</Radio>
                    <Radio value={false}>Quote</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={
                    <b>
                      {baseName} Amount ({baseCurrencyNameDisplay})
                    </b>
                  }
                  name="qty"
                  rules={[
                    {
                      type: "number",
                      message: "The input is not valid quantity!",
                    },
                    {
                      required: true,
                      message: "Please input correct quantity!",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    controls={false}
                    formatter={(value) => `${value?.toLocaleString("en-us")}`}
                    onChange={onQtyChange}
                  />
                </Form.Item>
                <Form.Item
                  label={<b>Venue Price</b>}
                  name="price"
                  rules={[
                    {
                      type: "number",
                      message: "The input is not valid price!",
                    },
                    {
                      required: true,
                      message: "Please input correct price!",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    controls={false}
                    formatter={(value) => `${value?.toLocaleString("en-us")}`}
                    onChange={onQtyChange}
                  />
                </Form.Item>
                <Row justify={"space-between"}>
                  <Form.Item
                    label={<b>Venue Fee (BPS)</b>}
                    name="fee"
                    style={{ width: "90%" }}
                    rules={[
                      {
                        type: "number",
                        message: "The input is not valid fee!",
                      },
                    ]}
                    initialValue={defaultFeeObj[selectedVenue]?.venue_fees}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      controls={false}
                      formatter={(value) => `${value?.toLocaleString("en-us")}`}
                      onChange={onFeeChange}
                    />
                  </Form.Item>
                  <Tooltip title="Include Venue Fees">
                    <Form.Item
                      name="includeFees"
                      valuePropName="checked"
                      initialValue={true}
                    >
                      <Checkbox onChange={onIncludeFeeCheck} />
                    </Form.Item>
                  </Tooltip>
                </Row>
                <Row justify={"space-between"}>
                  {dclFeeCalculationType === "bps" ? (
                    <Form.Item
                      label={<b>Delchain Fee (BPS)</b>}
                      name="dclFee"
                      rules={[
                        {
                          type: "number",
                          message: "The input is not valid fee!",
                        },
                      ]}
                      style={{ width: "90%" }}
                      initialValue={defaultFeeObj[selectedVenue]?.dcl_fees}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        formatter={(value) =>
                          `${value?.toLocaleString("en-us")}`
                        }
                        onChange={onFeeChange}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item label={<b>Delchain Fee (BPS)</b>}>
                      {dclFees}
                    </Form.Item>
                  )}

                  <Tooltip title="Include Delchain Fees">
                    <Form.Item
                      name="includeDclFees"
                      valuePropName="checked"
                      initialValue={true}
                    >
                      <Checkbox onChange={onIncludeFeeCheck} />
                    </Form.Item>
                  </Tooltip>
                </Row>
                {form.getFieldValue("includeDclFees") ? (
                  <Row justify={"space-between"}>
                    <Form.Item
                      label={<b>Delchain fees calculation</b>}
                      name="dclFeeCalculationType"
                      style={{ width: "90%" }}
                      initialValue={"bps"}
                    >
                      <Radio.Group
                        options={feeCalculationTypeOptions}
                        onChange={(e) => {
                          onDclFeeTypeChange(e);
                        }}
                        value={dclFeeCalculationType}
                      />
                    </Form.Item>
                  </Row>
                ) : undefined}
              </Col>

              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                {includeNetFees ? (
                  <React.Fragment>
                    <Form.Item label={<b>Total Fee (BPS)</b>}>
                      <Space className="calculated">
                        {netFees.toLocaleString("en-us")}
                      </Space>
                    </Form.Item>
                    <Form.Item
                      label={<b>Total Fee ({quoteCurrency})</b>}
                      style={
                        metMinimumFeeRequirement
                          ? {
                              color: "black",
                            }
                          : {
                              color: "red",
                            }
                      }
                    >
                      <Space className="calculated">
                        {isQuoteFiat
                          ? Number(
                              Number(netFeeQuote).toFixed(2),
                            ).toLocaleString("en-us", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : netFeeQuote.toLocaleString("en-us")}
                      </Space>
                    </Form.Item>
                    {includeVenueFees ? (
                      <Form.Item label={<b>Venue Fee ({quoteCurrency})</b>}>
                        <Space className="calculated">
                          {isQuoteFiat
                            ? Number(
                                Number(netVenueFeeQuote).toFixed(2),
                              ).toLocaleString("en-us", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : netVenueFeeQuote.toLocaleString("en-us")}
                        </Space>
                      </Form.Item>
                    ) : undefined}
                    {includeDelchainFee ? (
                      <Form.Item
                        label={<b>Net Delchain Fee ({quoteCurrency})</b>}
                        style={
                          metMinimumDclFeeRequirement
                            ? {
                                color: "black",
                              }
                            : {
                                color: "red",
                              }
                        }
                      >
                        {dclFeeCalculationType === "bps" ? (
                          <Space className="calculated">
                            {isQuoteFiat
                              ? Number(
                                  Number(netDclFeeQuote).toFixed(2),
                                ).toLocaleString("en-us", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : netDclFeeQuote.toLocaleString("en-us")}
                          </Space>
                        ) : (
                          <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) =>
                              `${value?.toLocaleString("en-us")}`
                            }
                            onChange={onDclNetFeeQuoteChange}
                          />
                        )}
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ) : (
                  <></>
                )}
                <Form.Item label={<b>Indicative Price</b>}>
                  <Space className="calculated">
                    {isBaseStablecoin
                      ? Number(
                          Number(netPrice.replaceAll(",", "")).toFixed(4),
                        ).toLocaleString("en-us", {
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        })
                      : netPrice}
                  </Space>
                </Form.Item>
                <Form.Item
                  label={
                    <b>
                      {quoteName} Amount ({quoteCurrencyNameDisplay})
                    </b>
                  }
                >
                  <Space className="calculated">
                    {isQuoteFiat && isBase
                      ? Number(
                          Number(quoteQty.replaceAll(",", "")).toFixed(2),
                        ).toLocaleString("en-us", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : quoteQty}
                  </Space>
                </Form.Item>
                {minimumAmountComponent}
              </Col>
            </Row>
            <Divider />
            <Row justify={"space-between"}>
              <Col xl={{ span: 13 }} xs={{ span: 23 }} sm={{ span: 23 }}>
                <Form.Item label={<b>Note</b>} name="note">
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 13 }} xs={{ span: 23 }} sm={{ span: 23 }}>
                <Form.Item label={<b>Message</b>}>
                  <Space>
                    {messageToCopy}
                    <Button onClick={copyMessageToClipboard}>
                      <FontAwesomeIcon
                        icon={"fa-regular fa-copy" as IconProp}
                      />
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col xl={{ span: 13 }} xs={{ span: 23 }} sm={{ span: 23 }}>
                <Form.Item label={<b>Summary</b>}>
                  <Space>
                    {summary}
                    <Button onClick={copySummaryToClipboard}>
                      <FontAwesomeIcon
                        icon={"fa-regular fa-copy" as IconProp}
                      />
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Form.Item>
                <Button
                  danger
                  onClick={() => {
                    setShowNewOrderBuilder(false);
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  // htmlType="submit"
                  className="button-save-as-draft"
                  onClick={() => {
                    setShowConfirmModal(true);
                    setSaveType("draft");
                  }}
                >
                  Save as Draft
                </Button>
                <Button
                  type="primary"
                  className="button-save-as-canceled"
                  // htmlType="submit"
                  onClick={() => {
                    setShowConfirmModal(true);
                    setSaveType("canceled");
                  }}
                >
                  Save as Canceled
                </Button>
                <Button
                  type="primary"
                  className="button-save-as-executed"
                  onClick={() => {
                    setShowConfirmModal(true);
                    setSaveType("executed");
                  }}
                  style={{
                    marginRight: "30px",
                  }}
                >
                  Save as Executed
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Content>
      </React.Fragment>

      <Modal
        width={"80%"}
        open={showConfirmModal}
        okText={
          saveType
            ? `Save as ${saveType.charAt(0).toUpperCase() + saveType.slice(1)}`
            : "Confirm"
        }
        cancelButtonProps={{
          danger: true,
          loading: !onFinishLoaded,
        }}
        okButtonProps={{
          loading: !onFinishLoaded,
        }}
        onOk={() => {
          submitData(saveType);
        }}
        onCancel={() => {
          setSaveType("");
          setShowConfirmModal(false);
        }}
      >
        <DclQuoteConfirmationModal
          date={dayjs(form.getFieldValue("timestamp"))
            .tz("America/New_York", true)
            .format()}
          isBase={isBase}
          symbol={symbolPair}
          venue={
            venueList.find(
              (row: any) => row.value === form.getFieldValue("venueId"),
            )?.label
          }
          orderType={
            dclOrderTypeList.find(
              (row: any) => row.value === form.getFieldValue("dclOrderTypeId"),
            )?.label
          }
          client={clientName}
          transactionWay={transactionWayName}
          qty={form.getFieldValue("qty")}
          price={form.getFieldValue("price")}
          venueFee={form.getFieldValue("fee") ? form.getFieldValue("fee") : 0}
          dclFee={
            form.getFieldValue("dclFee") ? form.getFieldValue("dclFee") : 0
          }
          note={form.getFieldValue("note")}
          msg={messageToCopy}
          summary={summary}
        />
      </Modal>
    </ConfigProvider>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default CreateNewDclQuote;
